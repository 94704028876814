<template>
    <section class="retur">
        <div class="top-bar bg-white" style="justify-content: left">
            <el-date-picker
                    v-model="formSearch.dt1" type="datetimerange" range-separator="至" size="small"
                    value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" style="width: 350px;margin-right: 10px"
                    start-placeholder="支付开始日期" end-placeholder="支付结束日期"/>
            <el-date-picker
                    v-model="formSearch.dt2" type="datetimerange" range-separator="至" size="small"
                    value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" style="width: 350px;margin-right: 10px"
                    start-placeholder="账单开始日期" end-placeholder="账单结束日期"/>
            <el-select v-model="formSearch.status" placeholder="请选择账单状态" style="width: 150px;margin-right: 10px" size="small">
                <el-option label="全部" :value="0"/>
                <el-option label="待处理" :value="600601"/>
                <el-option label="已支付" :value="600602"/>
                <el-option label="已作废" :value="600604"/>
            </el-select>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
            <el-button type="primary" size="small" @click="handleExport">导出数据</el-button>
        </div>
        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getByleasorListData" :columns="tableColumn" :query="formSearch"
                   :height="600">
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </section>
</template>

<script>
    import {houseOtherBillColumns} from "@/views/rental-management/tenant-management/data";
    import {byleasorList,byleasorExport} from '@/api/tenant-management'
    import {downloadByData} from "@/utils/hooks/download";
    import { timeFormat } from "@custom/index";
    export default {
        name: "retur",
        props: {
            tenantData: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                tableColumn: houseOtherBillColumns(this),
                formSearch: {
                    status:0,
                },
            };
        },
        methods: {
            getByleasorListData(params) {
                params.uuid = this.tenantData.uuid;
                return byleasorList(params);
            },
            handleSearch() {
                this.$refs["tableRef"].getTableData();
            },
            handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            handleExport() {
                const loading = this.$loading({lock: true, text: "正在导出请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                let {uuid} = this.tenantData;
                let {paidStartDate, paidEndDate, billStartDate, billEndDate,status} = this.formSearch;
                let paramData = { uuid, paidStartDate, paidEndDate, billStartDate, billEndDate, status};
                byleasorExport(paramData).then(res => {
                    downloadByData({data: res, filename: `租户账单${timeFormat(new Date(),'yyyy-MM-dd-HH-mm-ss')}.xlsx`});
                    loading.close();
                }).catch(err => loading.close());
            }
        },
        async mounted() {},
        watch:{
            'formSearch.dt1'(value) {
                if (value) {
                    let [paidStartDate, paidEndDate] = value;
                    this.formSearch.paidStartDate = paidStartDate;
                    this.formSearch.paidEndDate = paidEndDate;
                }
            },
            'formSearch.dt2'(value) {
                if (value) {
                    let [billStartDate, billEndDate] = value;
                    this.formSearch.billStartDate = billStartDate;
                    this.formSearch.billEndDate = billEndDate;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .retur {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                //white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>